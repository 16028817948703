import React from "react";

const axios = require('axios');

// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Collapse,
  Label,
  FormGroup,
  Input,
  Table,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";

const MINUTE_MS = 300000;

function Landing() {

    const [openedCollapses, setOpenedCollapses] = React.useState([]);
    const [priceOvrRequests, setPriceOvrRequests] = React.useState([]);
    const userDetails = JSON.parse(localStorage.getItem('userGraphDetails'));
    const sourceEnv = process.env.REACT_APP_SOURCE_ENV;
    const endPointEnv = sourceEnv && sourceEnv === 'prod' ? "prod" : "qa";

    React.useEffect(() => {
        loadPriceOvrRequests();
        const interval = setInterval(() => {
            //console.log('Logs every minute');
            loadPriceOvrRequests();
        }, MINUTE_MS);
        
        return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
    }, [])

    const collapsesToggle = (collapse) => {
        if (openedCollapses.includes(collapse)) {
          setOpenedCollapses(openedCollapses.filter((item) => item !== collapse));
        } else {
          openedCollapses.push(collapse);
          setOpenedCollapses([...openedCollapses, collapse]);
        }
    };

    const loadPriceOvrRequests = () => {

        let config = {
            headers: {'x-api-key': 'afNiKT1sv73znpuvldQ8T4I2S20AstUU9TLhWfB6'},
            params: {
                storeId : sourceEnv && sourceEnv === 'test' ? "220" : userDetails.storeId
            }
        }

        axios.get(`https://mdiq30gz71.execute-api.us-west-2.amazonaws.com/${endPointEnv}/override/price`, config).then(function (response) {
            console.log(response.data);
            if(response.data && response.data.reqs){
                setPriceOvrRequests(response.data.reqs);
            }else{
                setPriceOvrRequests([]);
            }
        });
    }

    const postPriceOvrAction = (docId, action) => {

        let config = {
            headers: {'x-api-key': 'afNiKT1sv73znpuvldQ8T4I2S20AstUU9TLhWfB6'}
        }

        var req = {
            "docId" : docId,
            "action" : action,
            "userName" : userDetails.displayName,
            "userId" : userDetails.employeeId
        }

        axios.post(`https://mdiq30gz71.execute-api.us-west-2.amazonaws.com/${endPointEnv}/override/price/action`, req, config).then(function (response) {
            
            var respData = response.data;
            if(respData.statusCode === '200'){
                var respBody = JSON.parse(respData.body);
                respBody = JSON.parse(respBody);
                if(respBody.result === 'updated'){
                    setPriceOvrRequests(priceOvrRequests.filter(item => item.docId != docId));
                }
            }
        });
    }

    return (
      <>
        <div className="content">
        <Row>
            <Col className="text-center" lg="12" md="12">
                <Card className="card-tasks">
                <CardHeader>
                    <CardTitle tag="h4">Tasks</CardTitle>
                    <h5 className="card-category">Price Override Requests</h5>
                </CardHeader>
                <CardBody className="card-collapse">
                {
                    priceOvrRequests && priceOvrRequests.length > 0 ? 

                    priceOvrRequests.map(
                        (entry, index) => {
                            return <div key={entry.docId}>
                                <Card className="card-plain">
                                    <CardHeader role="tab">
                                    <a
                                        aria-expanded={openedCollapses.includes("collapseOne")}
                                        href="#"
                                        data-parent="#accordion"
                                        data-toggle="collapse"
                                        onClick={() => collapsesToggle(entry.docId)}
                                    >
                                        <p className="pri-ovr-item-desc">{entry.itemDesc}{" "}</p>
                                        <i className="nc-icon nc-minimal-down" />
                                    </a>
                                    </CardHeader>
                                </Card>
                                <Collapse
                                    role="tabpanel"
                                    isOpen={openedCollapses.includes(entry.docId)}
                                    >
                                    <CardBody>
                                    <Row xs="1" md="4">
                                        <Col>
                                            <h6>
                                                Item# <br />
                                            </h6>
                                            <p>{entry.itemId}</p>
                                        </Col>
                                        <Col>
                                            <h6>
                                                Requested By <br />
                                            </h6>
                                            <p>{entry.requestedBy ? entry.requestedBy : "NA"}</p>
                                        </Col>
                                        <Col>
                                            <h6>
                                                Actual Price <br />
                                            </h6>
                                            <p>{entry.originalPrice}</p>
                                        </Col>
                                        <Col>
                                            <h6>
                                                New Price <br />
                                            </h6>
                                            <p>{entry.newPrice}</p>
                                        </Col>
                                        <Col>
                                            <h6>
                                                Cost <br />
                                            </h6>
                                            <p>{entry.cost}</p>
                                        </Col>
                                        <Col>
                                            <h6>
                                                Min Price <br />
                                            </h6>
                                            <p>{entry.minPrice}</p>
                                        </Col>
                                        <Col>
                                            <h6>
                                                Min Price Override<br />
                                            </h6>
                                            <p>{entry.minPriceOvr === 'Y' ? 'Yes' : 'No'}</p>
                                        </Col>
                                        <Col>
                                            <h6>
                                                Reason Code<br />
                                            </h6>
                                            <p>{entry.reasonCode}</p>
                                        </Col>
                                        <Col>
                                            <h6>
                                                Reason<br />
                                            </h6>
                                            <p>{entry.reason}</p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <h6>
                                                Notes<br />
                                            </h6>
                                            <p>{entry.notes}</p>
                                        </Col>
                                    </Row>
                                    <hr/>
                                    </CardBody>
                                    <CardFooter>
                                        <Row>
                                            <Col>
                                                <Button color="success" className="btn-pri-ovr" onClick={() => postPriceOvrAction(entry.docId, 'approved')}>
                                                    <span className="btn-label">
                                                        <i className="nc-icon nc-check-2" />
                                                    </span>
                                                    Approve
                                                </Button>
                                            </Col>
                                            <Col>
                                                <Button color="danger" className="btn-pri-ovr" onClick={() => postPriceOvrAction(entry.docId, 'declined')}>
                                                    <i className="nc-icon nc-simple-remove" />
                                                    Decline
                                                </Button>
                                            </Col>
                                        </Row>
                                    </CardFooter>
                                </Collapse>
                            </div>
                        }
                    ) : 
                    <>
                        <Card className="card-plain">
                            <CardBody>
                                <span>No pending requests</span>
                            </CardBody>
                        </Card>
                    </>
                }
                </CardBody>
                <CardFooter>
                    <div className="stats">
                    <i className="fa fa-refresh spin" />
                        Auto updates every 5 min
                    </div>
                </CardFooter>
                </Card>
            </Col>
        </Row>
        </div>
      </>
    )
}

export default Landing;