export const msalConfig = {
    auth: {
      clientId: "0c165ded-a02c-4396-b212-d0fb2ef22631",
      authority: "https://login.microsoftonline.com/2903b32e-b3db-4aad-8b64-f3f7b64e235c",
      redirectUri: process.env.REACT_APP_AD_REDIRECT_URL
    },
    cache: {
      cacheLocation: "sessionStorage", // This configures where your cache will be stored
      storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    }
  };
  
  // Add scopes here for ID token to be used at Microsoft identity platform endpoints.
  export const loginRequest = {
    scopes: ["User.Read"]
  };
  
  // Add the endpoints here for Microsoft Graph API services you'd like to use.
  export const graphConfig = {
      graphMeEndpoint: "https://graph.microsoft.com/v1.0/me?$select=id,employeeId,displayname,mail,officeLocation,jobTitle,mobilePhone,onPremisesExtensionAttributes,userPrincipalName,photo"
  };