import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { loginRequest } from "./authConfig";
import AuthLayout from "layouts/Auth.js";
import AdminLayout from "layouts/Admin.js";

function App(props){

    function ErrorComponent({error}) {
        return <p>An Error Occurred: {JSON.stringify(error)}</p>;
    }
    
    function LoadingComponent() {
        return <p>Authentication in progress...</p>;
    }

    return (
        <MsalAuthenticationTemplate 
            interactionType={InteractionType.Redirect} 
            authenticationRequest={loginRequest} 
            errorComponent={ErrorComponent} 
            loadingComponent={LoadingComponent}
        >
            <BrowserRouter>
                <Switch>
                    <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
                    <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
                    <Redirect to="/admin/dashboard" />
                </Switch>
            </BrowserRouter>
        </MsalAuthenticationTemplate>
    )

}

export default App;