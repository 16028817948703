import React from "react";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
import { Route, Switch, useLocation } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { loginRequest, graphConfig } from "../authConfig";
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Footer from "components/Footer/Footer.js";
import Sidebar from "components/Sidebar/Sidebar.js";

import routes from "routes.js";

var ps;

function Admin(props) {
  const location = useLocation();
  const [backgroundColor, setBackgroundColor] = React.useState("black");
  const [activeColor, setActiveColor] = React.useState("info");
  const [sidebarMini, setSidebarMini] = React.useState(false);
  const mainPanel = React.useRef();
  const { instance, accounts } = useMsal();
  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      document.documentElement.className += " perfect-scrollbar-on";
      document.documentElement.classList.remove("perfect-scrollbar-off");
      ps = new PerfectScrollbar(mainPanel.current);
    }
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
        document.documentElement.className += " perfect-scrollbar-off";
        document.documentElement.classList.remove("perfect-scrollbar-on");
      }
    };
  });
  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainPanel.current.scrollTop = 0;
  }, [location]);
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  React.useEffect(() => {

    if(accounts && accounts.length > 0) {
      //console.log(accounts[0]);
      const request = {
        ...loginRequest,
        account: accounts[0]
      };

      // Silently acquires an access token which is then attached to a request for Microsoft Graph data
      instance.acquireTokenSilent(request).then((response) => {
          //setAccessToken(response.accessToken);
          console.log("Acquired Access Token");
          callMsGraph(response.accessToken)
      }).catch((e) => {
          console.log(e);
      });

    }

  }, []);
  async function callMsGraph(accessToken) {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;
    //console.log(accessToken);
    headers.append("Authorization", bearer);

    const options = {
        method: "GET",
        headers: headers
    };
    console.log("Calling Graph API");
    return fetch(graphConfig.graphMeEndpoint, options)
        .then(response => readGraphApiResponse(response))
        .catch(error => console.log(error));
  }
  async function readGraphApiResponse(response){
    var responseBody = await response.json();
    var storeId = getStoreIdFromPrincipal(responseBody.onPremisesExtensionAttributes.extensionAttribute3);
    responseBody.storeId = storeId;
    localStorage.setItem("userGraphDetails", JSON.stringify(responseBody));
  }
  function getStoreIdFromPrincipal(extensionAttr){
    var extParts = extensionAttr.split('|');
    var storeId = extParts[0].trim().replace('GC', '');
    return storeId;
}
  const handleActiveClick = (color) => {
    setActiveColor(color);
  };
  const handleBgClick = (color) => {
    setBackgroundColor(color);
  };
  const handleMiniClick = () => {
    if (document.body.classList.contains("sidebar-mini")) {
      setSidebarMini(false);
    } else {
      setSidebarMini(true);
    }
    document.body.classList.toggle("sidebar-mini");
  };
  return (
    <div className="wrapper">
      <Sidebar
        {...props}
        routes={routes}
        bgColor={backgroundColor}
        activeColor={activeColor}
        account={accounts[0]}
      />
      <div className="main-panel" ref={mainPanel}>
        <AdminNavbar {...props} handleMiniClick={handleMiniClick} />
        <Switch>{getRoutes(routes)}</Switch>
        {
          // we don't want the Footer to be rendered on full screen maps page
          props.location.pathname.indexOf("full-screen-map") !== -1 ? null : (
            <></>
          )
        }
      </div>
    </div>
  );
}

export default Admin;
