import Landing from "views/Landing.js";

const routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "nc-icon nc-bank",
    component: Landing,
    layout: "/admin",
  }
];

export default routes;
